var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SBusinessCart',{ref:"cartRef",attrs:{"size":"80","type":_vm.type,"scene":_vm.scene,"state":_vm.state,"promotion-id":_vm.promotionId,"base-config":_vm.baseConfig,"locals":_vm.locals,"ui-config":_vm.uiConfig,"bottom-placeholder":true,"address":_vm.addressInfo,"user-local-size-country":_vm.userLocalSizeCountry,"theme":_vm.theme,"theme-vars":_vm.themeVars},on:{"cartUpdated":_vm.onCartUpdated,"quick-add":_vm.handleQuickAdd,"re-pick":_vm.handleRePick},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [_c('SPopover',{staticClass:"business-cart-popover",attrs:{"trigger-type":"user","show-close-icon":"","append-to-body":false,"prop-style":{ width: '90vw'},"placemen":"top"},model:{value:(_vm.popoverShow),callback:function ($$v) {_vm.popoverShow=$$v},expression:"popoverShow"}},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
          id: '1-8-4-21',
          data: { state: _vm.state }
        }),expression:"{\n          id: '1-8-4-21',\n          data: { state: state }\n        }"},{name:"tap",rawName:"v-tap",value:({
          id: '1-8-4-20',
          data: { state: _vm.state }
        }),expression:"{\n          id: '1-8-4-20',\n          data: { state: state }\n        }"}],staticClass:"unchecked-items",on:{"click":function($event){return _vm.openBusinessCart(_vm.unCheckedGoodsList[0])}}},[_c('div',{staticClass:"goods"},[_c('PopoverGoods',{attrs:{"support-item-info":_vm.supportItemInfo}})],1),_vm._v(" "),_c('div',{staticClass:"unchecked-tips"},[_vm._v("\n          "+_vm._s(_vm.supportItemInfo.tipText)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"pick-btn"},[_vm._v("\n          "+_vm._s(_vm.supportItemInfo.pickText)+"\n        ")])]),_vm._v(" "),_c('template',{slot:"reference"},[_c('div',{staticClass:"business-cart-foot",style:({
            ..._vm.styleConfig,
          })},[_c('section',{staticClass:"top"},[_c('p',{staticClass:"add-on-tips",domProps:{"innerHTML":_vm._s(_vm.tips)}}),_vm._v(" "),(_vm.showProgress)?_c('div',{staticClass:"progress"},[_vm._t("progress")],2):_vm._e()]),_vm._v(" "),_vm._t("top"),_vm._v(" "),_c('section',{staticClass:"bottom"},[_c('div',{staticClass:"left"},[_c('SBadge',{attrs:{"type":"danger","value":_vm.cartCheckedNum,"max":99,"badge-style":{
                  bottom: '72%',
                  [_vm.GB_cssRight ? 'right' : 'left']: '90%',
                }}},[_c('img',{directives:[{name:"expose",rawName:"v-expose",value:({
                    id: '1-8-4-19',
                    data: { state: _vm.state }
                  }),expression:"{\n                    id: '1-8-4-19',\n                    data: { state: state }\n                  }"},{name:"tap",rawName:"v-tap",value:({
                    id: '1-8-4-18',
                    data: { state: _vm.state }
                  }),expression:"{\n                    id: '1-8-4-18',\n                    data: { state: state }\n                  }"}],staticClass:"j-add-on-business-cart",attrs:{"src":"https://img.ltwebstatic.com/images3_acp/2024/03/15/a4/1710507571e64bf0c57e6ee9e2f3321242b1cb00f3.png","width":"60px"},on:{"click":_vm.openBusinessCart}})]),_vm._v(" "),_c('div',{staticClass:"price"},[(_vm.totalPrice.price)?_c('PriceCountTo',{staticClass:"price-total",style:({
                    'color': _vm.totalPrice.color,
                  }),attrs:{"amount":_vm.totalPrice.price.amount}}):_vm._e(),_vm._v(" "),(_vm.showDiscountPrice)?_c('p',{staticClass:"price-saved",style:({
                    'color': _vm.savedPrice.color,
                  })},[_c('span',[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_29458)+" ")]),_vm._v(" "),_c('b',[_c('PriceCountTo',{attrs:{"amount":_vm.savedPrice.price.amount}})],1)]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"right"},[_c('s-button',{staticClass:"go-checkout",class:{
                  'scan-lights': _vm.status > 0,
                },attrs:{"type":['H80PX', 'primary']},on:{"click":function($event){return _vm.$emit('checkoutClick')}}},[_vm._v("\n                "+_vm._s(_vm.language.SHEIN_KEY_PWA_27304)+"\n              ")]),_vm._v(" "),_c('IncentivePoint',{staticClass:"incentive-point",attrs:{"tags":_vm.tags,"bubble":_vm.bubble}})],1)])],2)])],2)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }