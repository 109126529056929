import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'

/**
 * @description: open quick add in pwa used by business-cart component
 * @param {Object} ctx business-cart ctx, with { type, item, callBacks }
 * @param {Object} quickAdd pwa quickAdd Object with open function
 * @param {Object} daEventCenter pwa daEventCenter with triggerNotice function
 * @return {*}
 */
export async function openQuick4BCart(ctx = {}, quickAdd, daEventCenter) {
  const item = ctx.item
  const callBacks = ctx.callBacks
  const sku_sale_attr = item.product?.sku_sale_attr || {}
  let selectedAttrIndex = {}
  if(item.attr?.attr_id) {
    selectedAttrIndex[item.attr?.attr_id] = { 
      attr_value_id: item.attr?.attr_value_id
    }
  }
  sku_sale_attr && sku_sale_attr.forEach(item => {
    if(!item.is_main){
      selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
    }
  })
  const imgRatio = await getImageRatio(item.product?.goods_img)
  daEventCenter.addSubscriber({ modulecode: '1-8-3' })
  quickAdd.open({
    goods_id: item.product?.goods_id || '',
    imgRatio,
    selectedAttrIndex,
    mallCode: item.mall_code,
    isShowEdit: true,
    isShowWishBtn: false,
    item_id: item.id,
    quantity: item.quantity,
    clickCallBack: {
      edit: callBacks.editComplete,
      mallClick: () => {
        daEventCenter.triggerNotice({
          daId: '1-8-3-12',
          extraData: {
            sku_id: item.product?.goods_sn
          }
        })
      },
      isActive: (show) => {
        show && callBacks?.daTriggerNotice({
          daId: '8',
          extraData: {
            sku_id: item.product?.goods_sn,
          }
        })
      }
    },
    analysisConfig: {
      code: 'cart',
      from: 'cart',
      source: 'page',
    },
    showPromotionTagInfo: false,
  })
}
